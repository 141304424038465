<script setup>
import { useScreen } from "./useScreen";

const el = ref(null);
const screen = ref(null);
const screenContainer = inject("screenGroup");

provide(
  "screen",
  computed(() => screen.value)
);

//when root el is rendered, register screen
watchEffect(() => register(el.value));

function register() {
  if (!el.value) return;

  screen.value = useScreen(computed(() => el.value));
  screenContainer.register(screen.value);
}
</script>
<template>
  <div ref="el" class="v-screen">
    <slot></slot>
  </div>
</template>

<style scoped>
.v-screen {
  position: absolute;
  top: 0px;
  left: 100%;
  height: 100% !important;
  width: 100%;
  /* background-color: var(--dialog-bg); */
  /* background: var(--root-grey2); */

  z-index: 0;
  overscroll-behavior: auto;
  color: var(--text-color);
  overflow: hidden;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  /* padding-left: var(--screen-padding); */
  /* padding-right: var(--screen-padding); */
  visibility: hidden;
}

.screen-margin {
  /* padding-top: var(--header-height); */
  /* padding-bottom: var(--footer-height); */
  /* box-sizing: inherit; */
  /* box-sizing: border-box; */
}

.movable {
  transition: 220ms transform var(--layer-transition);
  will-change: transform;
  background-color: var(--root-white);
}

.first {
  left: 0px;
}

.left {
  transform: translate3d(-120%, 0, 0) !important;
  will-change: transform;
}

.center {
  transform: translate3d(-100%, 0, 0) !important;
  will-change: transform;
}

.right {
  transform: translate3d(0%, 0, 0) !important;
  will-change: transform;
}
</style>
